.cardBorderGradient {
  position: relative;
  background-clip: padding-box;
  border: solid 3px transparent;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px;
    border-radius: inherit;
    background: rgb(248, 247, 247);
    background: linear-gradient(
      60deg,
      rgba(248, 247, 247, 0.6) 30%,
      rgba(102,0,201,1) 96%
    );
  }
}

.tooltip2 {
  width: 15rem;
  padding: 1rem;
}

.border-top-ass-card {
  border-top: 1px solid rgba(229, 231, 235, 1);
}

.avatar-profile-conatier-team {
  #avatar-profile {
    background-color: antiquewhite;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    transition: background ease-out 200ms;
  }

  #preview-profile {
    position: relative;
    border: 4px solid #6600C9;
    border-radius: 50%;
  }

  input[type='file'] {
    display: none;
  }

  #upload-button-avatar {
    border-radius: 100%;
    cursor: pointer;
    background-color: #6600C9;
    transition: background-color ease-out 120ms;
    position: absolute;
    right: 2%;
    bottom: 5%;
  }
}

.tooltip {
  width: 10rem;
  padding: 1rem;
}

@media screen and (max-width: 321px) {
  .single-jobCard {
    width: 290px;
    margin: auto;
    max-width: 290px;
    min-height: 266px;
    max-height: 266px;
  }
}

@media screen and (min-width: 321px) and (max-width: 640px) {
  .single-jobCard {
    width: 340px;
    margin: auto;
    max-width: 340px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .single-jobCard {
    width: 420px;
    // margin: auto;
    max-width: 420px;
  }
}

@media screen and (min-width: 640px) and (max-width: 850px) {
  .single-jobCard {
    width: 325px;
    // margin: auto;
    max-width: 325px;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1280px) {
  .single-jobCard {
    width: 350px;
    // margin: auto;
    max-width: 350px;
  }
}

@media screen and (min-width: 1280px) {
  .single-jobCard {
    width: 400px;
    min-height: 240px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'assets/styles/customPrimeReact';
@import 'assets/styles/customReactTabs';
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;700;800;900&display=swap');

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}

// Experimental

#App-root {
  background: url(./assets/images/bg.svg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: 'Tajawal', 'Lato', sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Note: backdrop-filter has minimal browser support */
  // background: url(./assets/images/bg.svg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // transform: matrix(-1, 0, 0.01, 1, 0, 0);
  color: #333333;

  scrollbar-width: thin; /*thin;*/
  scrollbar-color: #eb249e #ffffff;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 7.5px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      90deg,
      #a200ca -33.05%,
      #e200aa 145%
    ); /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }

  .scroller {
    scrollbar-width: thin; /*thin;*/
    scrollbar-color: #eb249e #ffffff;
    scrollbar-gutter: stable;
  }
  .scroller::-webkit-scrollbar {
    width: 7.5px;
    height: 7.5px;
  }
  .scroller::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
  }

  .scroller::-webkit-scrollbar-thumb {
    background: linear-gradient(
      90deg,
      #a200ca -33.05%,
      #e200aa 145%
    ); /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }
}

// text gradient
.text-gradient {
  background: linear-gradient(93.57deg, #7a53bf -2.84%, #ed2c94 46.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

// gradient border
.border-gradient {
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #a200ca -33.05%, #e200aa 145%);
}

.App {
  background: rgba(
    255,
    255,
    255,
    0.2
  ); // Make sure this color has an opacity of less than 1
  // backdrop-filter: blur(5px);
  min-height: 95vh;
}

.forgt-conta {
  min-height: 90vh;
}

.auth-bg-overlay {
  background: transparent;
  min-height: 86vh;
}

.header-dd {
  width: 12rem !important;
}

#profile-con {
  max-height: 86vh !important;
  overflow: hidden !important;
}

#RFS-Connector {
  border-color: #a200ca !important;
}

#RFS-Label {
  margin-top: 35px !important;
  font-size: 16px !important;
}

@media screen and (min-width: 1500px) {
  .auth-bg-overlay {
    background: url(./assets/images/authentication/authIlls.svg) no-repeat;
    background-attachment: fixed;
    -webkit-background-size: 750px auto;
    -moz-background-size: 750px auto;
    -o-background-size: 750px auto;
    background-size: 750px auto !important;
  }
  .auth-bg-right {
    background-position: 95% 60%;
  }
  .auth-bg-left {
    background-position: 10% 60%;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1499px) {
  .auth-bg-overlay {
    background: url(./assets/images/authentication/authIlls.svg) no-repeat;
    background-attachment: fixed;
    -webkit-background-size: 650px auto;
    -moz-background-size: 650px auto;
    -o-background-size: 650px auto;
    background-size: 650px auto !important;
  }
  .auth-bg-right {
    background-position: 95% 60%;
  }
  .auth-bg-left {
    background-position: 10% 60%;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1499px) {
  .auth-bg-overlay {
    background: url(./assets/images/authentication/authIlls.svg) no-repeat;
    background-attachment: fixed;
    -webkit-background-size: 450px auto;
    -moz-background-size: 450px auto;
    -o-background-size: 450px auto;
    background-size: 600px auto !important;
  }
  .auth-bg-right {
    background-position: 95% center;
  }
  .auth-bg-left {
    background-position: 10% center;
  }
}
@media screen and (min-width: 890px) and (max-width: 1099px) {
  .auth-bg-overlay {
    background: url(./assets/images/authentication/authIlls.svg) no-repeat;
    // -webkit-background-size: 200px 150px;
    // -moz-background-size: 200px 150px;
    // -o-background-size: 200px 150px;
    background-size: 500px 400px;
    background-attachment: fixed;
  }
  .auth-bg-right {
    background-position: 95% center;
  }
  .auth-bg-left {
    background-position: 10% center;
  }
}

.sucss-reg-bg {
  background: url(./assets/images/authentication/success_bg.png) no-repeat
    center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.assignmentInput {
  .p-inputtextarea {
    background-color: #f8f8f8;
    border: none !important;
  }
  .p-inputtextarea:focus {
    box-shadow: none !important;
  }
}

/**
 * Custom styles for react-select
 */

.react-select-container {
  margin-top: 6px;
}

.react-select__option {
  background-color: white !important;
}
.react-select__menu-list {
  max-height: 220px !important;
  scrollbar-width: thin; /*thin;*/
  scrollbar-color: #a64dff #ffffff;

  &::-webkit-scrollbar {
    width: 7.5px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a64dff; /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }
}
.react-select__control {
  border-color: #6600c9 !important;
  box-shadow: none !important;
  padding: 2px 0 !important;
}
.react-select__indicator-separator {
  display: none;
}

.react-select__option {
  background-color: white !important;
  color: #000 !important;
}

.react-select-container-location {
  margin-top: 6px;
}

.react-select-location__option {
  background-color: white !important;
  color: #000 !important;
}

.react-select-location__control {
  // border-color: transparent !important;
  border-bottom: #6600c9 2px solid !important;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  box-shadow: none !important;
  padding: 0px 0 !important;
  border-radius: 0px !important;
  background-color: transparent !important;
}
.react-select-location__indicator-separator {
  display: none;
}

.react-select-location__value-container {
  padding: 2px 4px;
}

.react-select-location__placeholder {
  font-size: 14px !important;
  white-space: nowrap !important;
}

.react-select-location__menu-list {
  max-height: 220px !important;
  scrollbar-width: thin; /*thin;*/
  scrollbar-color: #eb249e #ffffff;
  &::-webkit-scrollbar {
    width: 7.5px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      90deg,
      #a200ca -33.05%,
      #e200aa 145%
    ); /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }
}

.react-select-container-sm {
  margin-top: 6px;
  font-size: 12px;
  font-weight: bold;
}

.react-select-sm__option {
  background-color: white !important;
  color: #000 !important;
}

.react-select-sm__control {
  border-color: transparent !important;
  box-shadow: none !important;
  padding: 0px 0 !important;
}
.react-select-sm__indicator-separator {
  display: none;
}

.react-select-sm__placeholder {
  font-size: 12px !important;
  white-space: nowrap !important;
}

.react-select-sm__menu-list {
  max-height: 220px !important;
  scrollbar-width: thin; /*thin;*/
  scrollbar-color: #a200ca #ffffff;
  &::-webkit-scrollbar {
    width: 7.5px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      90deg,
      #a200ca -33.05%,
      #e200aa 145%
    ); /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }
}

.react-select-container-sm-time {
  margin-top: 6px;
  font-size: 12px;
}

.react-select-sm-time__option {
  background-color: white !important;
  color: #000 !important;
}

.react-select-sm-time__control {
  border-color: transparent !important;
  box-shadow: none !important;
  padding: 0px 0 !important;
}
.react-select-sm-time__indicator-separator {
  display: none;
}

.react-select-sm-time__placeholder {
  font-size: 12px !important;
  white-space: nowrap !important;
}

.react-select-sm-time__menu {
  font-size: 13px !important;
}

.react-select-sm-time__menu-list {
  max-height: 220px !important;
  scrollbar-width: thin; /*thin;*/
  scrollbar-color: #a200ca #ffffff;
  &::-webkit-scrollbar {
    width: 7.5px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      90deg,
      #a200ca -33.05%,
      #e200aa 145%
    ); /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }
}

.react-select-job-stage-psy__option {
  background-color: white !important;
  color: #000 !important;
}

.react-select-job-stage-psy__control {
  box-shadow: none !important;
  padding: 0px 0 !important;
  border-color: #6600c9 !important;
}

/**
 * Quil editor styles
 */

.editor {
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    height: 238px;
    border-color: #6600c9;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .ql-editor {
    &::-webkit-scrollbar {
      width: 7.5px;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b163ff; /* color of the scroll thumb */
      border-radius: 2px; /* roundness of the scroll thumb */
      /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
    }
  }

  .ql-toolbar.ql-snow {
    border: 1px solid #6600c9 !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #ecececcc;
  }
}

.editor2 {
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    height: 150px !important;
    border-color: #6600c9;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ql-editor {
    &::-webkit-scrollbar {
      width: 7.5px;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b163ff; /* color of the scroll thumb */
      border-radius: 2px; /* roundness of the scroll thumb */
      /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
    }
  }

  .ql-toolbar.ql-snow {
    border: 1px solid #6600c9 !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #ecececcc;
  }
}

.assess-editor {
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-color: #6600c9;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .ql-editor {
    &::-webkit-scrollbar {
      width: 7.5px;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b163ff; /* color of the scroll thumb */
      border-radius: 2px; /* roundness of the scroll thumb */
      /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
    }
  }

  .ql-toolbar.ql-snow {
    border: 1px solid #6600c9 !important;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    background: #f4f4f4 !important;
  }
}

/**
 * Applicant Card details
 */

.applicants_card_bg {
  background: linear-gradient(
    115.95deg,
    rgba(248, 247, 247, 0.6) 10.92%,
    rgba(255, 255, 255, 0.08) 96.4%
  );
  backdrop-filter: blur(73.6347px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10.4393px;
  // transform: matrix(-1, 0, 0.01, 1, 0, 0);
}

/**
 * Notification Panel bg
 */

.notification-panel-overlay-bg {
  background-color: rgba(9, 30, 66, 0.54) !important;
}

@keyframes inAnimation {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes outAnimation {
  20% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.prose {
  max-width: 100% !important;
}

.p-dialog .p-dialog-footer button {
  width: 110px !important;
}

.upgradeBackgroundImages {
  background-image: url(./assets/images/profile/UpgradeArrowIllus.svg),
    url(./assets/images/profile/NoteBookIllus.svg),
    url(./assets/images/profile/UpgradeBottomRight.svg);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-attachment: fixed, fixed, fixed;
  background-position: 45% 90%, 40% 30%, 90% 80%;
}

.vertical-text {
  transform: rotate(270deg);
  color: #ffffff;
  padding: 2rem;
}

.reactour__popover,
.reactour__mask {
  border-radius: 0.5rem;
}

.viewApplicant-modal {
  .p-dialog .p-dialog-header {
    padding: 0px !important;
  }
}

.uppy-size--height-md .uppy-Dashboard-AddFiles-info {
  display: none !important;
}

.uppy-Dashboard-AddFiles {
  height: 80% !important;
  padding-top: 40px !important;
}

.uppy-Dashboard-AddFiles-title {
  font-size: 16px !important;
  color: #828282 !important;
}

[data-uppy-drag-drop-supported='true'] .uppy-Dashboard-AddFiles {
  height: 80% !important;
  border: none !important;
}

.uppy-StatusBar-progress {
  background-color: #6600c9 !important;
}

.uppy-size--md .uppy-DashboardContent-addMore {
  display: flex !important;
  color: #6600c9 !important;
}

.uppy-StatusBar-actionBtn--upload {
  background-color: #6600c9 !important;
  border-radius: 5px !important;
  width: auto !important;
  margin: 0px auto !important;
}

.uppy-Dashboard {
  height: 80% !important;
}

.uppy-Dashboard-inner {
  min-height: 420px !important;
  max-height: 420px !important;
  border: 1px dashed #6600c9 !important;
}

.uppy-DashboardContent-back,
.uppy-Dashboard-browse {
  color: #6600c9 !important;
}

.uppy-Dashboard-browse:hover {
  border-bottom: 1px solid #6600c9 !important;
}

.rtf--mb {
  > * {
    transform-origin: center center;
    transform: none !important;
    transition: none !important;
  }
}

.rtf--mb__c {
  padding: 0 0 25px !important;
}

.accordion-demo .accordion-custom i,
.accordion-demo .accordion-custom span {
  vertical-align: middle;
}

.accordion-demo .accordion-custom span {
  margin: 0 0.5rem;
}

.accordion-demo .p-accordion p {
  line-height: 1.5;
  margin: 0;
}

.p-accordion-header-link {
  box-shadow: none !important;
}

.p-float-label > label {
  display: flex;
  right: 0.5rem !important;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  margin-right: 0px !important;
}

.file-upload {
  border: 1px solid #6600c9;
  background-color: #6600c9;
  border-radius: 3px;
  padding: 3px;
}

.file-upload input {
  overflow: hidden;
  width: 0;
}
